import React from 'react';
import Navigation from './Navigation';
// import MyMiniPlaylist from './NavigationElements/MyMiniPlaylist';
import AudioPlayer from './AudioPlayer';
// import Drawer from './Drawer';
import Footer from './Footer';

export default function AppBar(props) {
  
  return (
    <div>
      <Navigation />
      {/* <MyMiniPlaylist className="mini-playlist" /> */}
      <Footer />
      <AudioPlayer />
    </div>


  );
}